<template>
  <div class="resume">
    <div id="resume_header">
      <h1>Quinn Pearce</h1>
      <span class="left">quinn.pearce@gmail.com</span>
      <span class="right"
        ><a href="https://www.linkedin.com/in/quinn-pearce"
          >linkedin.com/in/quinn-pearce</a
        ></span
      >
      <br />
      <hr />
      <br />
    </div>

    <div id="work_experience">
      <h4>Work Experience</h4>
      <hr />
      <div class="resume_container">
        <span class="entry_one">
          <span class="left">USAA</span>
          <span class="right">Jan. 2018 – Present</span> </span
        ><br />
        <span class="entry_two">
          <span class="left">Software Engineer</span>
          <span class="right">San Antonio, TX and Mesa, AZ</span> </span
        ><br />
        <ul class="one_col_list">
          <li>
            Managed, improved, and monitored a data science workbench platform for about
            400 data scientists and facilitated the development of machine learning
            models.
          </li>
          <li>
            Built and tested custom Python packages and Airflow operators to help
            orchestrate model scoring processes for Data Engineers.
          </li>
          <li>
            Created and maintained fully automated CI/CD pipelines for production model
            deployment, using GitLab, Docker, and Airflow.
          </li>
          <li>
            Collaborated with a distributed agile team to help prioritize and deliver
            feature requests from business partners across several company lines of
            business.
          </li>
          <li>Wrote Puppet manifests to declaratively configure and manage servers.</li>
        </ul>
        <br />
      </div>
      <div class="resume_container">
        <span class="entry_one">
          <span class="left">Texas Tech University</span>
          <span class="right">Aug. 2015 – May. 2017</span> </span
        ><br />
        <span class="entry_two">
          <span class="left">Graduate Assistant</span>
          <span class="right">Lubbock, TX</span> </span
        ><br />
        <ul class="one_col_list">
          <li>
            Organized and presented lecture material to several classes of 30-50
            undergraduate students.
          </li>
          <li>
            Communicated with students and discussed their academic needs to provide
            actionable feedback.
          </li>
          <li>
            Provided feedback on assignments & retaught unclear concepts to improve
            students’ subject mastery.
          </li>
        </ul>
        <br />
      </div>
      <div class="resume_container">
        <span class="entry_one">
          <span class="left">Texas Tech University</span>
          <span class="right">Aug. 2013 – May. 2015</span> </span
        ><br />
        <span class="entry_two">
          <span class="left">Math/Stats Tutor</span>
          <span class="right">Lubbock, TX</span> </span
        ><br />
        <ul class="one_col_list">
          <li>
            Engaged with students in 1-on-1 discussions & small group lessons to reinforce
            their mathematical reasoning
          </li>
          <li>
            Regularly coordinated with administration to ensure students needs were met.
          </li>
        </ul>
        <br />
      </div>
    </div>

    <div id="certifications">
      <h4>Certifications</h4>
      <hr />
      <div class="resume_container">
        <ul class="two_col_list">
          <li v-for="item in my_certs">
            {{ item }}
          </li>
        </ul>
        <br />
      </div>
    </div>

    <div id="skills_interests">
      <h4>Skills</h4>
      <hr />
      <div class="resume_container">
        <ul class="three_col_list">
          <li v-for="item in my_skills">
            {{ item }}
          </li>
        </ul>
        <br />
      </div>
      <h4>Interests</h4>
      <hr />
      <div class="resume_container">
        <ul class="three_col_list">
          <li v-for="item in my_hobbies">
            {{ item }}
          </li>
        </ul>
        <br />
      </div>
    </div>

    <div id="education">
      <h4>Education</h4>
      <hr />
      <div class="resume_container">
        <span class="entry_one">
          <span class="left">Texas Tech University</span>
          <span class="right">May, 2017</span> </span
        ><br />
        <span class="entry_two">
          <span class="left">MS, Statistics</span>
          <span class="right">Lubbock, TX</span> </span
        ><br />
        <ul class="one_col_list">
          <li>Summa cum laude; 4.0/4.0 GPA</li>
        </ul>
        <br />
      </div>
      <div class="resume_container">
        <span class="entry_one">
          <span class="left">Texas Tech University</span>
          <span class="right">May, 2015</span> </span
        ><br />
        <span class="entry_two">
          <span class="left">BS, Mathematics (minor Computer Science)</span>
          <span class="right">Lubbock, TX</span> </span
        ><br />
        <ul class="one_col_list">
          <li>Summa cum laude; 3.94/4.0 GPA</li>
        </ul>
        <br />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ResumeView",
  setup() {},
  data() {
    return {
      my_certs: [
        "AWS Cloud Practitioner",
        "AWS Solutions Architect - Associate",
        "AWS Developer - Associate",
      ],
      my_skills: [
        "Python",
        "AWS",
        "Apache Airflow",
        "Docker",
        "Git/GitLab",
        "Unix/Linux",
        "MongoDB",
        "Agile Methodologies",
        "Test Development",
        "Puppet",
        "Datadog",
        "LDAP",
      ],
      my_hobbies: [
        "Baking",
        "Board Games",
        "Cooking",
        "Hiking",
        "Reading",
        "Sudoku",
        "Tabletop Games",
      ],
    };
  },
};
</script>

<style scoped>
* {
  max-width: 700px;
  margin: auto;
  font-family: Arial, Helvetica, sans-serif;
  text-align: left;
}

hr {
  margin-top: 0px;
  margin-bottom: 10px;
}

.resume_container {
  font-size: 16px;
}

.left {
  float: left;
}

.right {
  float: right;
}

.entry_one {
  font-weight: bold;
}

.entry_two {
  font-style: italic;
}

.one_col_list {
  -webkit-column-count: 1;
  /* Chrome, Safari, Opera */
  -moz-column-count: 1;
  /* Firefox */
  column-count: 1;
  font-size: 16px;
}

.two_col_list {
  -webkit-column-count: 2;
  /* Chrome, Safari, Opera */
  -moz-column-count: 2;
  /* Firefox */
  column-count: 2;
  font-size: 16px;
}

.three_col_list {
  -webkit-column-count: 3;
  /* Chrome, Safari, Opera */
  -moz-column-count: 3;
  /* Firefox */
  column-count: 3;
  font-size: 16px;
}
</style>
