import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import ResumeView from '../views/ResumeView.vue'
import KitchenUnitConverter from '../views/tools/KitchenUnitConverter.vue'
import WIPSoon from '../views/tools/WIPSoon.vue'
import SomethingElse from '../views/tools/SomethingElse.vue'
import NotFound from '../views/NotFound.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: {
      auth: false,
      title: 'Home'
    },
  },
  {
    path: '/resume',
    name: 'resume',
    component: ResumeView,
    meta: {
      auth: false,
      title: 'Resume - Quinn Pearce'
    },
  },
  {
    path: '/tools/kitchen_converter',
    name: 'kitchen_converter',
    component: KitchenUnitConverter,
    meta: {
      auth: false,
      title: 'Kitchen Unit Conversions'
    },
  },
  {
    path: '/tools/WIPSoon',
    name: 'wip_soon',
    component: WIPSoon,
    meta: {
      auth: false,
      title: 'Future Project'
    },
  },
  {
    path: '/tools/something_else',
    name: 'something_else',
    component: SomethingElse,
    meta: {
      auth: false,
      title: 'Test Buttons'
    },
  },
  { //Redirect 'about' to resume
    path: '/about',
    redirect: '/resume',
  },
  { //catchall 404
    path: '/:catchAll(.*)',
    name: 'NotFound',
    component: NotFound,
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  linkActiveClass: "active", // active class for non-exact links.
  linkExactActiveClass: "active", // active class for *exact* links.
  routes
})

export default router
