<template>
  <form onsubmit="return false;">
    <table>
      <tr>
        <td>
          <span>Unit type: </span>
        </td>
        <td>
          <span v-for="item in phys_dimension">
            <input
              type="radio"
              name="phys_dimension"
              :id="item.id"
              :value="item.id"
              v-model="prop_dimension"
              @input="$emit('update:prop_dimension', $event.target.value)"
            />
            <label class="noselect" :for="item.id">{{ item.name }}</label>
          </span>
        </td>
      </tr>
      <tr>
        <td>
          <span>Input Amount: </span>
        </td>
        <td>
          <input
            type="number"
            v-model="prop_input_value"
            @input="$emit('update:prop_input_value', $event.target.value)"
          />
        </td>
      </tr>

      <tr>
        <td>
          <span>Input Units: </span>
        </td>
        <td>
          <span v-for="item in availableUnits">
            <input
              type="radio"
              name="input_units"
              :id="item.id + input_marker"
              :value="item.id"
              v-model="prop_input_units"
              @input="$emit('update:prop_input_units', $event.target.value)"
            />
            <label class="noselect" :for="item.id + input_marker">{{ item.name }}</label>
          </span>
        </td>
      </tr>
      <tr>
        <td>
          <span>Output Units: </span>
        </td>
        <td>
          <span v-for="item in availableUnits">
            <input
              type="radio"
              name="output_units"
              :id="item.id + output_marker"
              :value="item.id"
              v-model="prop_output_units"
              @input="$emit('update:prop_output_units', $event.target.value)"
            />
            <label class="noselect" :for="item.id + output_marker">{{ item.name }}</label>
          </span>
        </td>
      </tr>
      <tr>
        <td class="submitter" colspan="2">
          <button
            id="form_submitter"
            class="btn btn-md"
            type="submit"
            @click="$emit('submit_form')"
          >
            Submit
          </button>
        </td>
      </tr>
    </table>
  </form>
</template>

<script>
export default {
  setup() {},
  props: ["prop_dimension", "prop_input_units", "prop_input_value", "prop_output_units"],

  methods: {
    dimension_change(ev) {
      this.$emit("update:prop_dimension", ev.target.value);
      this.$emit("update:prop_input_units", ev.target.value);
      this.$emit("update:prop_output_units", ev.target.value);
    },
  },
  name: "UnitsForm",
  data() {
    return {
      phys_dimension: [
        { name: "Temperature", id: "temp", checked: true },
        { name: "Weight/Mass", id: "mass", checked: false },
        { name: "Volume", id: "volume", checked: false },
      ],
      input_marker: "_input_unit",
      output_marker: "_output_unit",
      allUnits: {
        temp: [
          { name: "Celsius", id: "celsius" },
          { name: "Fahrenheit", id: "fahrenheit" },
        ],
        mass: [
          { name: "Grams", id: "gram" },
          { name: "Kilograms", id: "kilogram" },
          { name: "Ounces", id: "oz" },
          { name: "Pounds", id: "pound" },
        ],
        volume: [
          { name: "Milliliters", id: "ml" },
          { name: "Liters", id: "liter" },
          { name: "Teaspoons", id: "teaspoon" },
          { name: "Tablespoons", id: "tablespoon" },
          { name: "Fluid Ounces", id: "fl_oz" },
          { name: "Cups", id: "cup" },
          { name: "Pints", id: "pint" },
          { name: "Quarts", id: "quart" },
          { name: "Gallons", id: "gallon" },
        ],
      },
    };
  },
  computed: {
    availableUnits() {
      return this.allUnits[this.prop_dimension];
    },
    computed_dimension() {
      return this.prop_dimension;
    },
  },
  watch: {
    //Can't watch a prop.
    computed_dimension(newDimension, oldDimension) {
      this.$emit("update:prop_input_units", null);
      this.$emit("update:prop_output_units", null);
    },
  },
};
</script>

<style scoped>
input {
  padding: 4px;
  margin: 8px;
}
input[type="radio"] {
  display: none;
}
input[type="radio"] + label {
  display: inline-block;
  
  margin-right: 4px;
  padding: 8px;
  border: none;
  border-radius: 4px;
  background-color: #e7e7e7;
  border-color: #ddd;
}
input[type="radio"]:checked + label {
  background-image: none;
  background-color: rgba(132, 250, 176, 0.5);
}
.btn-md:hover {
  background-color: rgba(143, 211, 244, 0.5);
}
.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
}
tr{
  height: 50px;
}
td.submitter {
  text-align: center;
  
}
form{
  display:inline-block;
  background-color: #F5F5F5;
  border-radius: 8px;
  padding: 10px;
  
}
</style>
