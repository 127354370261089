<template>
  <Navbar></Navbar>
  <div class="app_content">
    <router-view />
  </div>
</template>

<script>
import Navbar from "./components/Navbar.vue";
export default {
  name: "App",
  components: {
    Navbar,
  },
  setup() {},
  watch: {
    $route(to, from) {
      document.title = to.meta.title || "Quinn Pearce";
    },
  },
};
</script>
<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
.app_content {
  margin-left: 3%;
  margin-right: 3%;
  margin-top: 2%;
  font-family: Arial, Helvetica, sans-serif;
  text-align: left;
}
</style>
