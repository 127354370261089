<template>
  <head>
    <title>HTML Elements Reference</title>
  </head>
  <div>
    <h2>About Me</h2>
    Hey, I'm Quinn Pearce. I've got a background in Mathematics and Statistics, and have
    worked in an IT/DevOps role for close to 5 years.
    
    During that time I've developed a
    passion for DevOps, Data Science, and Cloud technologies. I've also achieved several
    AWS certifications (Cloud Practitioner, Solutions Architect Associate, and Developer
    Associate), and am actively studying to pick up more.
    <br /><br />
    In my spare time I like to play board games with friends, to cook or bake delicous
    sounding recipes, or tinker with interesting tech stacks or cloud tools.
    <br />
  </div>
  <hr />
  <br />
  <div>
    <h2>About this site</h2>
    This site is very much a work in progress, and changes a bit whenever I have new
    things I want to try out.<br />
    I'm also not usually a designer or a frontend guy, but I'm pretty happy with how this
    has started to come together.<br /><br />
    I mostly wanted to tinker with some AWS tools and learn a little Javascript (Vue), and making this site has been a
    good excuse for it.<br />
    <br />
    Services and toolsets I've used to stitch this all together (so far):
    <ul class="one_col_list">
      <li v-for="item in tech_stack">
        {{ item }}
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "HomeView",
  components: {},
  data() {
    return {
      tech_stack: [
        "AWS IAM",
        "AWS CloudFormation",
        "AWS S3",
        "AWS CloudFront",
        "AWS Lambda",
        "AWS API Gateway",
        "DynamoDB",
        "Vue 3",
        "Javascript",
        "HTML/CSS",
        "Python",
      ],
    };
  },
};
</script>
<style scoped></style>
