<template>
  <h1>Just some buttons I guess</h1>
  <p>Can I interest you in some completely ordinary buttons?</p>
  <button class="btn btn-md" @click="back">Go Back</button>
  <button class="btn btn-md" @click="forward">Go Forward</button>
  <button class="btn btn-md" @click="redirect">Redirect</button>
  <button class="btn btn-md" @click="call_oracle">???</button>
  <br /><br />
  <p>{{ answer }}</p>
  <p>And also this other button? It's mostly here for me to practice talking to DynamoDB.</p>
  <button class="btn btn-md" @click="the_button">The Button</button>
  <br /><br />
  <p>{{ button_text }}</p>
</template>

<script>
export default {
  setup() {},
  data() {
    return {
      button_endpoint: "https://api.qpearce.com/api/counter",
      button_text: "",
      answer: "",
    };
  },
  methods: {
    redirect() {
      this.$router.push({ name: "resume" });
    },
    back() {
      this.$router.go(-1);
    },
    forward() {
      this.$router.go(1);
    },
    async call_oracle() {
      this.answer = "Thinking...";
      try {
        const res = await fetch("https://yesno.wtf/api");
        this.answer = (await res.json()).answer;
      } catch (error) {
        this.answer = "Error! Could not reach the API. " + error;
      }
    },
    async the_button() {
      this.button_text = "Working...";

      try {
        const res = await fetch(this.button_endpoint, {
          headers: {
            "Content-type": "application/json",
          },
          method: "POST",
          body: JSON.stringify({}),
          mode: "cors",
        });
        this.response = (await res.json());
        this.button_text = "The button has been pressed " + this.response + " times."
      } catch (error) {
        this.button_text = "Error! Could not reach the API. " + error;
      }
    },
  },
};
</script>

<style scoped>
button {
  margin: 0 10px;
  padding: 10px;
  border: none;
  border-radius: 4px;
}
</style>
