<template>
  <h1>Kitchen Unit Conversions</h1>
  <p>
    Could I have done this with just JavaScript? Probably, but I wanted to make & call an API.
  </p>
  <UnitsForm
    v-model:prop_dimension="form_data.dimension"
    v-model:prop_input_units="form_data.input_units"
    v-model:prop_input_value="form_data.input_value"
    v-model:prop_output_units="form_data.output_units"
    v-on:submit_form="call_api"
  ></UnitsForm
  ><br />
  <p>Answer:{{ answer }}</p>
  <div id="myfooter" v-if="show_outputs">
    <button
      class="btn"
      type="button"
      data-bs-toggle="collapse"
      data-bs-target="#collapseExample"
      aria-expanded="false"
      aria-controls="collapseExample"
    >
      Selections
    </button>
    <div class="collapse" id="collapseExample">
      <br />
      <p>Good job, you found my debug outputs:</p>
      <p>Selections:</p>
      <p>dimension: {{ form_data.dimension }}</p>
      <p>input value: {{ form_data.input_value }}</p>
      <p>input units: {{ form_data.input_units }}</p>
      <p>output units: {{ form_data.output_units }}</p>
      <p>response: {{ response }}</p>
      <p>Answer:{{ answer }}</p>
    </div>
  </div>
</template>
<script>
import UnitsForm from "../../components/UnitsForm.vue";

export default {
  components: {
    UnitsForm,
  },
  setup() {},
  data() {
    return {
      api_endpoint: "https://api.qpearce.com/api/converter",
      form_data: {
        dimension: null,
        input_units: null,
        input_value: null,
        output_units: null,
      },
      response: {},
      answer: "Results here.",
      show_outputs: false,
    };
  },
  methods: {
    validate_form() {},
    async call_api() {
      this.answer = "Thinking...";

      try {
        const res = await fetch(this.api_endpoint, {
          headers: {
            "Content-type": "application/json",
          },
          method: "POST",
          body: JSON.stringify({
            phys_dimension: this.form_data.dimension,
            input_mag: this.form_data.input_value,
            input_unit: this.form_data.input_units,
            output_unit: this.form_data.output_units,
          }),
          mode: "cors",
        });
        this.response = (await res.json());
        this.answer = this.response.output_mag + " " + this.response.output_unit
      } catch (error) {
        this.answer = "Error! Could not reach the API. " + error;
      }
    },
  },
};
</script>

<style scoped>
* {
  margin: auto;
  padding: 0px 0px 0px 0px;
}
#myfooter {
  position: fixed;
  padding: 0px 0px 0px 10px;
  bottom: 0;
  width: 100%;
}
</style>
