<template>
<link
    rel="stylesheet"
    href="https://cdn.jsdelivr.net/npm/bootstrap@4.0.0/dist/css/bootstrap.min.css"
    integrity="sha384-Gn5384xqQ1aoWXA+058RXPxPg6fy4IWvTNh0E263XmFcJlSAwiGgFAW/dAiS6JXm"
    crossorigin="anonymous"
  />  
  <nav class="navbar navbar-expand-md navbar-light" style="background: linear-gradient(to right, rgba(132, 250, 176, 0.5), rgba(143, 211, 244, 0.5));">
    <div class="container-fluid">
      <a class="navbar-brand" href="#"></a>
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <ul class="navbar-nav me-auto mb-2 mb-lg-0">
          <li class="nav-item">
            <router-link class="nav-link" :to="{ name: 'home' }">Home</router-link>
          </li>
          <li class="nav-item dropdown">
            <a
              class="nav-link dropdown-toggle"
              href="#"
              id="navbarDropdown"
              role="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              Tools
            </a>
            <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
              <li><router-link class="dropdown-item" :to="{ name: 'kitchen_converter' }">Kitchen Unit Converter</router-link></li>
              <li><router-link class="dropdown-item" :to="{ name: 'something_else' }">Test Buttons</router-link></li>
              <li><hr class="dropdown-divider" /></li>
              <li><router-link class="dropdown-item" :to="{ name: 'wip_soon' }">Placeholder - ???</router-link></li>
            </ul>
          </li>
          <li class="nav-item">
            <router-link class="nav-link" :to="{ name: 'resume' }"
              >Resume</router-link
            >
          </li>
          <li class="nav-item">
            <a class="nav-link" v-bind:href="linkedin_url">LinkedIn</a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>
<script>
export default {
  setup() {},
  name: "Navbar",
  data() {
    return {
      linkedin_url: "https://www.linkedin.com/in/quinn-pearce/",
    };
  },
};
</script>
<style>
.navbar .navbar-nav .nav-link {
    font-size: 1.1em;
}
</style>